<template>
  <el-dialog
    title="设置分类"
    width="30%"
    :modal-append-to-body="false"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    >
    <div>
      <el-select v-model="classId" placeholder="请选择">
          <el-option
              v-for="item in typeList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
      </el-select>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="submitForm">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import {noPage} from "@/api/customPageClass";
export default {
  data() {
    return {
      dialogVisible: false,
      typeList: [],
      classId: ''
    }
  },
  props: {
    // type:{
    //   type: [String, Number],
    //   default: 0,
    // }
  },
  methods: {
    open(type) {
      this.type = type
      this.classId=''
      this.initSelect()
      this.dialogVisible = true
    },
    close() {
      this.dialogVisible = false
      this.classId=''
    },
    initSelect() {
      noPage({type: this.type}).then(response=>{
        if (response.data){
          this.typeList = response.data
        }
      })
    },
    submitForm() {
      this.$parent.setPageClass(this.classId)
    }
  },
}
</script>