<template>
  <div class="view pa24">

    <el-dialog
        :title="title"
        width="800px"
        :modal-append-to-body="false"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        :before-close="closeDialog"
    >
      <el-form
          :model="ruleForm"
          :rules="rules"
          ref="dealerRuleForm"
          label-width="100px"
          class="ruleForm"
      >
        <el-form-item label="名称" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="排序" >
          <el-input type="number" v-model="ruleForm.sort"></el-input>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button class="btncss" @click="confirmAddCustomer">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {addPageClass} from '@/api/customPageClass.js'

export default {
  name: "addPageClass",
  components: {
    // BaiduMap,
  },
  props: [],

  data() {
    return {
      //测试
      provinces: [],
      classType:null,
      dialogVisible: false,
      title: '添加页面类别',
      ruleForm: {
        name: '',
      sort:999
      },
      rules: {
        name: [
          {required: true, message: '请输入类别名称', trigger: 'blur'},
        ],
        phone: [
          {required: true, message: '请输入联系电话', trigger: 'blur'},
          { type: 'number',
            message: '手机号格式不正确',
            trigger: 'blur',
            transform(value) {
              var phonereg = 11 && /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/
              if (!phonereg.test(value)) {
                return false
              } else {
                return Number(value)
              }
            }
          }
        ]

      },
      type: 'add',
      id: '',

    };
  },
  onShow() {

  },
  methods: {
    openDialog(type, data,classType) {
      this.dialogVisible = true
      let v = this
      v.classType = classType
      if (type === 'add') {
        this.title = '添加页面类别'
        this.type = 'add'
        this.id = ''
      } else {
        this.title = '编辑页面类别'
        this.getData(data)
        this.type = "edit"
        this.id = data.id
      }
    },
    closeDialog() {
      this.dialogVisible = false
      this.resetForm()
      this.$emit('getList')
    },
    resetForm() {
      this.$refs.dealerRuleForm.resetFields();
    },
    confirmAddCustomer() {
      this.$refs.dealerRuleForm.validate((valid) => {
        if (valid) {
          let params = {
            name: this.ruleForm.name,
            sort: this.ruleForm.sort,
            type:this.classType
          }
          if (this.type === 'edit') {
            params.id = this.id
            addPageClass(params).then(res => {
              if (res.code === 200) {
                this.$message({
                  type: "success",
                  message: "操作成功",
                });
                this.closeDialog()
              }
            })
          }else {
            addPageClass(params).then(res => {
              if (res.code === 200) {
                this.$message({
                  type: "success",
                  message: "操作成功",
                });
                this.closeDialog()
              }
            })
          }

        }
      })
    },
    // 回显
    getData(data) {
      this.ruleForm.name = data.name
      this.ruleForm.sort = data.sort
    },

  },


};
</script>

<style lang="scss" scoped>
.ml10 {
  margin-left: 10 rpx;
}
</style>
