<template>
  <div class="imgsPage">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="图片类别" name="first">
        <pageClassList ref="image" :classType="1" ></pageClassList>
      </el-tab-pane>
      <el-tab-pane label="图片列表" name="second">
         <div style="margin-top: 20px;">
          <el-form inline="true">
            <el-form-item label="图片类别" label-width="100px" label-position="left">
              <el-select v-model="classId" placeholder="请选择" clearable @change="classIdChange">
                <el-option
                    v-for="item in typeList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-button type="primary" class="myBtn" @click="reset">重置</el-button>
            <el-button type="primary" style="float:right" class="myBtn" @click="openSetClassDialog" :disabled="checkList.length ===0 " >设置分类</el-button>
          </el-form>
        </div>
        <el-row :gutter="20">
          <el-checkbox-group v-model="checkList">
            <el-col :span="6" v-for="(item,index) in imgList" :key="index"
              class="imgBlock">
                <div class="checkBoxDiv">
                  <el-checkbox :label="item.id">{{''}}</el-checkbox>
                </div>
                <el-image :src="ossUrl+item.fileUrl" fit="fill" :initial-index="index" :preview-src-list="calcSrcList(index)"/>
            </el-col>
          </el-checkbox-group>
        </el-row>
        <el-pagination :page-size.sync="params.pageSize" :total="total" :current-page.sync="params.pageNum"
          style="margin-top: 8px;" :page-sizes="pageSizes" layout="total, prev, pager, next, sizes"
          @size-change="pageSizeChange($event)" @current-change="pageChange($event)" />
      </el-tab-pane>
    </el-tabs>
    
    <!-- <div class="className"><span class="label">类别名称：</span>{{name}}</div> -->
    

    <SetClassDialog ref="setClassDialog"></SetClassDialog>
  </div>
</template>
<script>
import {
		fetchListCustom,
  } from '@/api/customPage'
import {noPage, bindPageClass} from "@/api/customPageClass";
import SetClassDialog from '@/pages/createPageList/components/setClassDialog'
import pageClassList from './pageClassList'
export default {
  data() {
    return {
      imgList: [],
      params: {
        pageNum: 1,
        pageSize: 12
      },
      total: 0,
      pageSizes: [12, 16, 20, 24],
      ossUrl: this.$store.state.ossUrl,
      classId: '',
      // name: '',
      typeList: [],
      checkList: [],
      activeName: 'first',
    }
  },
  created(option) {
    noPage({type:1}).then(response=>{
        if (response.data){
          this.typeList = response.data
        }
      })
    this.init()
    // this.name = this.$route.query.name
  },
  components: {
    SetClassDialog,
    pageClassList
  },
  methods: {
    setPageClass(classId) {
      let ids = this.checkList
      let params = {
        classId: parseInt(classId),
        pageIds: ids,
        type: 1,
      }
      bindPageClass(params).then(res => {
        this.$message.success('设置成功')
        this.$refs.setClassDialog.close()
        this.checkList = []
      })
    },
    openSetClassDialog() {
      this.$refs.setClassDialog.open(1)
    },
    reset() {
      this.params.pageNum = 1
      this.classId = ''
      this.init()
    },
    classIdChange(classId) {
      this.params.pageNum = 1
      this.init()
    },
    async init() {
      // console.log('id', this.$route.query.id)
      let companyId = localStorage.getItem('info') ? JSON.parse(localStorage.getItem('info')).companyId : ''
				let params = {
					...this.params,
					cId: companyId,
					classId: this.classId
				}
				let result = await fetchListCustom(params)
				if (result.data) {
					this.total = result.data.total
					this.imgList = result.data.list
        }
      
    },
    pageChange(value) {
      this.params.pageNum = value
      this.init()
    },
    pageSizeChange(value) {
      this.params.pageSize = value
      this.init()
    },
    calcSrcList(index) {
      let arr = []
      arr = this.imgList.map(item => this.ossUrl + item.fileUrl)
      return arr
    }

  }
}
</script>
<style scoped>
  .imgBlock{
    height: 250px;
    /* border: 1px solid #ccc; */
    box-sizing: border-box;
    margin-bottom: 40px;
    padding: 10px;
    overflow: hidden;
    border: 1px solid transparent;
    position: relative;
  }
  .checkBoxDiv{
    position: absolute;
    top: 15px;
    left: 20px;
  }
  .imgBlock:hover{
    border: 1px solid #74d7d5;
  }
  .myImg img{
    max-width: 100%;
    max-height: 100%;
  }
  .className{
    font-size: 15px;
    padding: 10px 0;
    color: #333;
  }
  .className .label {
    font-size: 15px;
    color: #74d7d5;
  }
  .imgsPage{
  }
</style>
